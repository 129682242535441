@use "design-sys" as *;

:root {
  // raised filled primary buttons
  --mat-tab-header-divider-height: 0;
  -mat-tab-header-divider-color: #f2f2f2;
  .mat-mdc-tab-group {
    .mat-mdc-tab-body-wrapper {
      display: grid;
      gap: 1.5rem;
      .mat-mdc-tab-body {
        .mat-mdc-tab-body-content {
          max-width: 100%;
          min-height: 10rem;
          margin: 0;
        }
      }
    }
  }
}

app-dashboard-widget-base .mat-mdc-tab-body-content {
  border-top: 1px solid $uni-base-palette-neutral90;
}
