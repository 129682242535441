@use "@angular/material" as mat;
@use "design-sys" as *;
@import url("https://fonts.googleapis.com/css2?family=Archivo");
@import url("https://fonts.googleapis.com/css2?family=Martel");

html {
  .loadingMode {
    position: relative;
    z-index: -1;
    * {
      button {
        pointer-events: none !important;
        cursor: not-allowed !important;
      }
    }
  }

  .hiddenElement {
    visibility: hidden !important;
    width: 0 !important;
    height: 0 !important;
  }

  .hide {
    display: none;
    visibility: hidden;
  }

  body {
    p {
      font-family: var(--body-font-family);
      font-size: var(--unnamed-font-size-14);
    }

    .rotate180 {
      rotate: 180deg;
    }

    .rotate90 {
      rotate: 90deg;
    }

    .mainInput {
      background: var(--white) 0% 0% no-repeat padding-box;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 16px;
      z-index: 1;
    }

    .navBarActiveRoute {
      font-weight: bold;
    }

    a {
      text-decoration: none !important;
      color: var(--uni-rol-clr-primary-light);
    }

    .text-center {
      text-align: center;
    }

    .modal-defaultButton {
      box-shadow: none !important;
    }

    .projectCard {
      box-shadow: var(--boxShadow);
      border-radius: var(--borderRadius);
      padding: 20px;
    }

    .clickableText {
      text-decoration: none;

      &:hover {
        cursor: pointer;
        color: var(--primary-color);
        text-decoration: none;
      }
    }

    .clickableCell {
      cursor: pointer;
    }

    .clickable {
      cursor: pointer;
    }

    .bold {
      font-weight: var(--fontWeightNormal);
    }

    .projectTableHeaderRowDragable {
      background-color: var(--white);
      border-bottom: 0.5px solid var(--borderColor);

      td {
        padding: 15px 12px;
        color: var(--black);
      }
    }

    .projectTable {
      width: 100%;
      background-color: var(--white);
      border-radius: var(--borderRadius);

      .centered {
        text-align: center;
      }

      .projectTableHeader {
        background-color: var(--tableHeaderBackground);

        th {
          padding: 8px 12px;
          color: var(--black);
          font-weight: var(--fontWeightNormal);

          &:first-child {
            border-top-left-radius: var(--borderRadius);
          }

          &:last-child {
            border-top-right-radius: var(--borderRadius);
          }
        }
      }

      .projectTableHeaderFixed {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .projectTableHeaderRow {
        background-color: var(--white);
        border-bottom: 0.5px solid var(--borderColorOpacity);

        td {
          padding: 8px 12px;
          color: var(--black);
          font-weight: 300;
        }
      }

      .projectTabHeaderRowNoBorder {
        border-bottom: none !important;
      }

      .projectTableHeaderRowHoverable {
        &:hover {
          cursor: pointer;
          background-color: var(--gray-opacity);
        }
      }

      .projectTableHeaderRowExpandedMain {
        .projectTableHeaderRowExpandedMainInner {
          background-color: rgba(248, 248, 248, 0.4);
        }
      }

      .projectTableHeaderRowExpanded {
        width: 85%;
        margin: 20px auto;
        min-height: 40px;
      }
    }

    .attachedFile {
      padding: 10px;
      border-radius: var(--borderRadius);
      box-shadow: var(--boxShadow);
    }

    .cardTable {
      width: 100%;

      th,
      td {
        border: 1px solid var(--borderColor);
        padding: 12px 10px;
      }

      th {
        background-color: var(--tableHeaderBackground);
        font-weight: var(--fontWeightNormal);
      }

      td {
        font-weight: 300;
      }
    }

    .controlSet {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }

    .operand {
      min-width: 1rem;
      max-width: 1.25rem;
      padding-top: 0.5rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      text-align: center;
      font-size: 1.75rem;
      line-height: 40px;
      font-weight: 400;
    }

    .modalCardTable {
      border-radius: var(--borderRadius);
      box-shadow: var(--boxShadow);
      width: 100%;

      .modalCardTableHeader {
        box-shadow: var(--boxShadow);
        background-color: var(--gray-opacity);

        th {
          padding: 8px;
          color: var(--black);

          &:first-child {
            border-top-left-radius: var(--borderRadius);
          }

          &:last-child {
            border-top-right-radius: var(--borderRadius);
          }
        }
      }

      .modalCardTableHeaderRow {
        background-color: var(--white);
        border-bottom: 1px solid var(--borderColor);

        td {
          padding: 8px;
          color: var(--black);
        }
      }
    }

    .settingsCard {
      display: flex;
      width: 100%;
      background-color: $uni-base-palette-neutral100;
      border-radius: $uni-rol-shp-card;
      padding: $uni-rol-spc-padding-sm;

      .settingsCardInner {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: $uni-base-palette-neutral100;
        border-radius: $uni-rol-shp-card;
      }
    }

    .formCard {
      @include mat.elevation(2);
      padding: $uni-rol-spc-padding-sm;
      border-radius: $uni-rol-shp-card-xs;
      background-color: $uni-base-palette-neutral100;
    }

    .formCardWithPadding {
      @include mat.elevation(2);
      padding: $uni-rol-spc-padding-md;
      border-radius: $uni-rol-shp-card-xs;
      background-color: $uni-base-palette-neutral100;
    }

    .detailsContainerHeader {
      .actions {
        display: flex;
        align-items: center;
        gap: 10px;

        mat-icon {
          font-size: 18px;
          line-height: 1.3;
        }

        .edit,
        .pay,
        .delete {
          cursor: pointer;
        }

        .edit {
          display: flex;
          align-items: center;
        }

        .delete {
          color: var(--error-color);
          font-weight: var(--fontWeightNormal);
        }
      }
    }

    .formActionsWithoutMargin {
      padding: 10px 0;
      margin-bottom: -20px !important;
    }

    .gridStatusMargin {
      margin-top: 10px;
    }

    .status-wrapper {
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 12px;
      height: 30px;
      // margin-top: 12px;
    }

    .draftStatus {
      background-color: #edeff1;
      color: #878787;
    }

    .completedStatus {
      background-color: var(--success-color-opacity);
      font-weight: 500;
      color: #00692a;
    }

    .createdStatus {
      background-color: #e8f1ff;
      color: #0250ec;
      font-weight: 500;
    }

    .canceledStatus {
      background-color: var(--gray-opacity);
      color: #919191;
    }

    .activeStatus {
      background-color: var(--success-color-opacity);
      color: var(--success-color);
      font-weight: 500;
    }

    .currentStatus {
      background-color: var(--success-color-opacity);
      color: var(--success-color);
    }

    .futureStatus {
      background-color: #f4f7ff;
      color: var(--info);
    }

    .returnedStatus {
      background-color: #ffe5e8;
      font-weight: 500;
      color: #d20319;
    }

    .creditReceivedStatus {
      background-color: #e9fbff;
      color: #00668f;
      font-weight: 500;
    }

    .managersApprovalStatus {
      background-color: #d8f7e6;
      color: #00692a;
      font-weight: 500;
    }

    .creditRecurvedStatus {
      background-color: #ffefe4;
      color: #ee5800;
      font-weight: 500;
    }

    .noStatus {
      background-color: none !important;
      color: #000;
    }

    .inActiveStatus {
      background-color: var(--warn-color-opacity);
      color: var(--error-color);
    }
  }
}

* {
  .e-dlg-container {
    z-index: 999 !important;
  }

  .d-none {
    display: none;
  }

  .mat-mdc-option {
    //.mat-mdc-option-item
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .employeeGroupSelect {
    .employeeImage {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .disabledText {
    color: #e0e0e0 !important;
  }

  .matMenuContainer {
    mat-icon {
      color: var(--iconColor) !important;
    }
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    max-width: initial !important;
  }

  .mat-calendar-body-selected {
    color: var(--white) !important;
  }

  .cdk-global-scrollblock {
    position: initial !important;
  }

  .mat-mdc-tooltip-panel-left {
    .customToolTip {
      .mdc-tooltip__surface {
        &:after {
          position: absolute;
          content: "";
          display: inline-block;
          background-color: var(--tooltip-background-color);
          clip-path: polygon(50% 0, 0 50%, 50% 100%);
          right: -7px;
          width: 15px;
          height: 15px;
          top: calc(50% - 7px);
          transform: rotate(180deg);
        }
      }
    }
  }

  .customToolTip {
    .mdc-tooltip__surface {
      padding: 12px;
      background-color: var(--tooltip-background-color) !important;
      font-size: var(--font-size) !important;
      color: var(--white);
      line-height: 20px;
      white-space: pre-line;
      text-align: left;
      overflow: inherit;
      font-weight: 300;
    }
  }

  .customMatInput {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
  }

  .customMatTextarea {
    .mat-mdc-form-field-subscript-wrapper {
      display: none !important;
    }
  }

  .lookupContainerMenu {
    .mat-mdc-menu-content {
      max-height: 240px !important;
      overflow-y: auto;
    }

    .lookupContainerMenuSpan {
      line-height: 20px;
    }
  }

  .lookupContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    .spinnerIcon {
      margin-right: 10px;
    }

    mat-form-field {
      width: 100%;
    }

    .lookupContainerMenuTrigger {
      width: 100%;
      height: var(--inputHeight);
      position: absolute;
      visibility: hidden;
      z-index: -1;
    }

    .lookupContainerDropdown {
      position: absolute;
      top: var(--inputHeight);
      left: 0;
      z-index: 21;
      border-radius: var(--borderRadius);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: var(--boxShadow);
      background-color: var(--white);
      //padding: 20px;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;

      .lookupContainerDropdownItem {
        padding: 10px;

        .itemName {
          color: var(--black);
        }

        &:hover {
          cursor: pointer;
          background-color: var(--headerBackground);
        }
      }

      .lookupContainerDropdownItemDisabled {
        cursor: not-allowed !important;
        pointer-events: none !important;

        .itemName {
          color: #e0e0e0 !important;
        }
      }
    }
  }

  .mbsc-popup-wrapper {
    z-index: 10 !important;
  }

  .mbscSchedulePopup {
    .mbscTemplatePopupGroup {
      display: flex !important;
      flex-direction: column !important;
      padding: 10px !important;
      gap: 12px !important;

      .employeeSelect {
        width: 100% !important;
      }

      .breakTimeContainer {
        display: flex;
        justify-content: space-between;

        .timeItemField {
          width: 46%;

          mat-form-field {
            width: 100%;
          }
        }
      }

      .resetTimesContainer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .mbscTooltipPopup {
    .customTooltipContainer {
      display: flex;
      padding: 15px;
      width: 100%;
      height: auto;
      flex-direction: column;
      gap: 7px;

      p {
        margin: 0;

        .bold {
          margin-right: 8px;
        }
      }

      .customTooltipContainerName {
        font-size: 15px;
      }

      .customTooltipContainerTimes {
        font-size: 13px;
      }
    }
  }

  @media print {
    html {
      overflow: visible !important;
    }

    .adminRouterOutletContainerShort {
      margin-left: 10px !important;
    }

    .adminRouterOutletContainerLong {
      margin-left: 10px !important;
    }

    .adminRouterOutletContainer {
      padding: 0 !important;
    }

    #navBarContainer {
      display: none !important;
    }

    .headerContainer {
      display: none !important;
    }
  }
}

.modalContainerBody {
  div.container form {
    padding: 0 24px;
  }

  .modalBottomContainer {
    display: flex;
    background-color: #f8fafa;
    border-top: 1px solid #e1e3e3;

    div.formActions {
      padding: 16px 24px;
      background-color: unset;
      margin-top: 0;

      .formActionsInner {
        display: flex;
        gap: 16px;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }
  }
}

.mat-expansion-panel-body {
  background: var(--white);
}

::ng-deep .cdk-global-scrollblock {
  position: initial !important;
}

.cdk-global-scrollblock {
  position: initial !important;
}

.gap-30 {
  background: #f8f8f882;
  padding: 21px;
  gap: 10px;
}

.p-l-r-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.toast-container {
  pointer-events: unset !important;
}

div.modalContainerBody {
  .mat-mdc-tab-body-content {
    padding: 24px;
  }
}

.tabZIndexAuto {
  .mat-mdc-tab-body.mat-mdc-tab-body-active {
    z-index: auto !important;
  }
}

.option-wrapper {
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  .description {
    color: #979797;
  }
}

mat-form-field {
  &.small {
    max-width: 100px;
  }

  &.medium {
    max-width: 250px;
  }

  &.full {
    width: 100%;
  }
}

.table-meta-header{
  width: 100%;
  display: flex;
  flex-flow:row nowrap;
  justify-content: space-between;
  align-items:baseline;

  .side-a,
  .side-b {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: baseline;
  }
}

table.mdc-data-table__table{
  border-top-color: var(--mat-table-row-item-outline-color);
  border-top-width: var(--mat-table-row-item-outline-width);
  border-top-style: solid;
  border-left-color: var(--mat-table-row-item-outline-color);
  border-left-width: var(--mat-table-row-item-outline-width);
  border-left-style: solid;
  border-right-color: var(--mat-table-row-item-outline-color);
  border-right-width: var(--mat-table-row-item-outline-width);
  border-right-style: solid;
  border-bottom-color: var(--mat-table-row-item-outline-color);
  border-bottom-width: var(--mat-table-row-item-outline-width);
  border-bottom-style: solid;
}

.genInfo-detail-flags {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .item-flags {
      flex-grow: 0;
      border: 1px solid var(--black);
      border-radius: var(--borderRadius);
      padding: 0px  0.8rem 0 0.6rem ;
      height: 2rem;
      font-family: Archivo;
      font-size: 12px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      white-space: nowrap;
      flex-shrink: 0;

      .mat-icon {
          margin: 0.3rem 0.5rem 0 0;
          width: 1.5rem;
          height: 1.25rem;
          font-size: 1.25rem;
          float: left;
      }
  }
}
