@use "token-roles" as *;


/**
* Expresses UniSight design system role tokens as CSS custom properties
*/
@mixin custom-props(){
// -------------------------------- Role Custom Props -------------------------------------
// These are custom props version of the role tokens.
// They are prescriptive; they have prescribed roles and can be used in
// any stylesheet. Recommended to populate component specific rules or custom
// properties. These were created (and can be edited) manually. Plan to replace these
// with generated files, once token service is built.
// ----------------------------------------------------------------------------------

// ----------------------------- Color Role Tokens ----------------------------------
  --uni-rol-clr-primary-light: #{$uni-rol-clr-primary-light};
  --uni-rol-clr-on-primary-light: #{$uni-rol-clr-on-primary-light};
  --uni-rol-clr-primary-container-light: #{$uni-rol-clr-primary-container-light};
  --uni-rol-clr-on-primary-container-light: #{$uni-rol-clr-on-primary-container-light};
  --uni-rol-clr-secondary-light: #{$uni-rol-clr-secondary-light};
  --uni-rol-clr-on-secondary-light: #{$uni-rol-clr-on-secondary-light};
  --uni-rol-clr-secondary-container-light: #{$uni-rol-clr-secondary-container-light};
  --uni-rol-clr-on-secondary-container-light: #{$uni-rol-clr-on-secondary-container-light};
  --uni-rol-clr-tertiary-light: #{$uni-rol-clr-tertiary-light};
  --uni-rol-clr-on-tertiary-light: #{$uni-rol-clr-on-tertiary-light};
  --uni-rol-clr-tertiary-container-light: #{$uni-rol-clr-tertiary-container-light};
  --uni-rol-clr-on-tertiary-container-light: #{$uni-rol-clr-on-tertiary-container-light};
  --uni-rol-clr-neutral-dark:  #{$uni-rol-clr-neutral-dark};
  --uni-rol-clr-error-light: #{$uni-rol-clr-error-light};
  --uni-rol-clr-error-container-light: #{$uni-rol-clr-error-container-light};
  --uni-rol-clr-on-error-light: #{$uni-rol-clr-on-error-light};
  --uni-rol-clr-on-error-container-light: #{$uni-rol-clr-on-error-container-light};
  --uni-rol-clr-background-light: #{$uni-rol-clr-background-light};
  --uni-rol-clr-on-background-light: #{$uni-rol-clr-on-background-light};
  --uni-rol-clr-surface-light: #{$uni-rol-clr-surface-light};
  --uni-rol-clr-on-surface-light: #{$uni-rol-clr-on-surface-light};
  --uni-rol-clr-surface-variant-light: #{$uni-rol-clr-surface-variant-light};
  --uni-rol-clr-on-surface-variant-light: #{$uni-rol-clr-on-surface-variant-light};
  --uni-rol-clr-outline-light: #{$uni-rol-clr-outline-light};
  --uni-rol-clr-inverse-on-surface-light: #{$uni-rol-clr-inverse-on-surface-light};
  --uni-rol-clr-inverse-surface-light: #{$uni-rol-clr-inverse-surface-light};
  --uni-rol-clr-inverse-primary-light: #{$uni-rol-clr-inverse-primary-light};
  --uni-rol-clr-shadow-light: #{$uni-rol-clr-shadow-light}; // ?
  --uni-rol-clr-surface-tint-light: #{$uni-rol-clr-surface-tint-light};
  --uni-rol-clr-outline-variant-light: #{$uni-rol-clr-outline-variant-light};
  --uni-rol-clr-scrim-light: #{$uni-rol-clr-scrim-light};
  --uni-rol-clr-primary-dark: #{$uni-rol-clr-primary-dark};
  --uni-rol-clr-on-primary-dark: #{$uni-rol-clr-on-primary-dark};
  --uni-rol-clr-primary-container-dark: #{$uni-rol-clr-primary-container-dark};
  --uni-rol-clr-on-primary-container-dark: #{$uni-rol-clr-on-primary-container-dark};
  --uni-rol-clr-secondary-dark: #{$uni-rol-clr-secondary-dark};
  --uni-rol-clr-on-secondary-dark: #{$uni-rol-clr-on-secondary-dark};
  --uni-rol-clr-secondary-container-dark: #{$uni-rol-clr-secondary-container-dark};
  --uni-rol-clr-on-secondary-container-dark: #{$uni-rol-clr-on-secondary-container-dark};
  --uni-rol-clr-tertiary-dark: #{$uni-rol-clr-tertiary-dark};
  --uni-rol-clr-on-tertiary-dark: #{$uni-rol-clr-on-tertiary-dark};
  --uni-rol-clr-tertiary-container-dark: #{$uni-rol-clr-tertiary-container-dark};
  --uni-rol-clr-on-tertiary-container-dark: #{$uni-rol-clr-on-tertiary-container-dark};
  --uni-rol-clr-error-dark: #{$uni-rol-clr-error-dark};
  --uni-rol-clr-error-container-dark: #{$uni-rol-clr-error-container-dark};
  --uni-rol-clr-on-error-dark: #{$uni-rol-clr-on-error-dark};
  --uni-rol-clr-on-error-container-dark: #{$uni-rol-clr-on-error-container-dark};
  --uni-rol-clr-background-dark: #{$uni-rol-clr-background-dark};
  --uni-rol-clr-on-background-dark: #{$uni-rol-clr-on-background-dark};
  --uni-rol-clr-surface-dark: #{$uni-rol-clr-surface-dark};
  --uni-rol-clr-on-surface-dark: #{$uni-rol-clr-on-surface-dark};
  --uni-rol-clr-surface-variant-dark: #{$uni-rol-clr-surface-variant-dark};
  --uni-rol-clr-on-surface-variant-dark: #{$uni-rol-clr-on-surface-variant-dark};
  --uni-rol-clr-outline-dark: #{$uni-rol-clr-outline-dark};
  --uni-rol-clr-inverse-on-surface-dark: #{$uni-rol-clr-inverse-on-surface-dark};
  --uni-rol-clr-inverse-surface-dark: #{$uni-rol-clr-inverse-surface-dark};
  --uni-rol-clr-inverse-primary-dark: #{$uni-rol-clr-inverse-primary-dark};
  --uni-rol-clr-shadow-dark: #{$uni-rol-clr-shadow-dark};
  --uni-rol-clr-surface-tint-dark: #{$uni-rol-clr-surface-tint-dark};
  --uni-rol-clr-outline-variant-dark: #{$uni-rol-clr-outline-variant-dark};
  --uni-rol-clr-scrim-dark: #{$uni-rol-clr-scrim-dark};
  --uni-rol-clr-primary: #{$uni-rol-clr-primary-light};
  --uni-rol-clr-on-primary: #{$uni-rol-clr-on-primary-light};
  --uni-rol-clr-primary-container: #{$uni-rol-clr-primary-container-light};
  --uni-rol-clr-on-primary-container: #{$uni-rol-clr-on-primary-container-light};
  --uni-rol-clr-secondary: #{$uni-rol-clr-secondary-light};
  --uni-rol-clr-on-secondary: #{$uni-rol-clr-on-secondary-light};
  --uni-rol-clr-secondary-container: #{$uni-rol-clr-secondary-container-light};
  --uni-rol-clr-on-secondary-container: #{$uni-rol-clr-on-secondary-container-light};
  --uni-rol-clr-tertiary: #{$uni-rol-clr-tertiary-light};
  --uni-rol-clr-on-tertiary: #{$uni-rol-clr-on-tertiary-light};
  --uni-rol-clr-tertiary-container: #{$uni-rol-clr-tertiary-container-light};
  --uni-rol-clr-on-tertiary-container: #{$uni-rol-clr-on-tertiary-container-light};
  --uni-rol-clr-error: #{$uni-rol-clr-error-light};
  --uni-rol-clr-error-container: #{$uni-rol-clr-error-container-light};
  --uni-rol-clr-on-error: #{$uni-rol-clr-on-error-light};
  --uni-rol-clr-on-error-container: #{$uni-rol-clr-on-error-container-light};
  --uni-rol-clr-background: #{$uni-rol-clr-background-light};
  --uni-rol-clr-on-background: #{$uni-rol-clr-on-background-light};
  --uni-rol-clr-surface: #{$uni-rol-clr-surface-light};
  --uni-rol-clr-on-surface: #{$uni-rol-clr-on-surface-light};
  --uni-rol-clr-surface-variant: #{$uni-rol-clr-surface-variant-light};
  --uni-rol-clr-on-surface-variant: #{$uni-rol-clr-on-surface-variant-light};
  --uni-rol-clr-outline: #{$uni-rol-clr-outline-light};
  --uni-rol-clr-inverse-on-surface: #{$uni-rol-clr-inverse-on-surface-light};
  --uni-rol-clr-inverse-surface: #{$uni-rol-clr-inverse-surface-light};
  --uni-rol-clr-inverse-primary: #{$uni-rol-clr-inverse-primary-light};
  --uni-rol-clr-shadow: #{$uni-rol-clr-shadow-light};
  --uni-rol-clr-surface-tint: #{$uni-rol-clr-surface-tint-light};
  --uni-rol-clr-outline-variant: #{$uni-rol-clr-outline-variant-light};
  --uni-rol-clr-scrim: #{$uni-rol-clr-scrim-light};

  // -------------------------- Typography Role Tokens --------------------------------
  // defaults
  --uni-rol-typ-default-size: #{$uni-rol-typ-default-size};
  --uni-rol-typ-default-weight: #{$uni-rol-typ-default-weight};
  --uni-rol-typ-default-line: #{$uni-rol-typ-default-line};
  --uni-rol-typ-default-spacing:  #{$uni-rol-typ-default-spacing};

  // M3 style type scale types (font faces)
  --uni-rol-typ-display-face: #{$uni-rol-typ-display-face};
  --uni-rol-typ-headline-face: #{$uni-rol-typ-headline-face};
  --uni-rol-typ-title-face: #{$uni-rol-typ-title-face};
  --uni-rol-typ-label-face: #{$uni-rol-typ-label-face};
  --uni-rol-typ-body-face: #{$uni-rol-typ-body-face};
  --uni-rol-typ-note-face: #{$uni-rol-typ-note-face};

  // headlines
  --uni-rol-typ-title-h1-face: #{$uni-rol-typ-title-h1-face}; // Archivo
  --uni-rol-typ-title-h1-size: #{$uni-rol-typ-title-h1-size}; // 32
  --uni-rol-typ-title-h1-weight: #{$uni-rol-typ-title-h1-weight}; // 700
  --uni-rol-typ-title-h1-line: #{$uni-rol-typ-title-h1-line}; // 108%
  --uni-rol-typ-title-h1-space: #{$uni-rol-typ-title-h1-space};
  // h2
  --uni-rol-typ-title-h2-face: #{$uni-rol-typ-title-h2-face}; // Archivo
  --uni-rol-typ-title-h2-size: #{$uni-rol-typ-title-h2-size}; // 22
  --uni-rol-typ-title-h2-weight: #{$uni-rol-typ-title-h2-weight}; //400
  --uni-rol-typ-title-h2-line: #{$uni-rol-typ-title-h2-line}; // 127
  --uni-rol-typ-title-h2-space: #{$uni-rol-typ-title-h2-space};
  // h3
  --uni-rol-typ-title-h3-face: #{$uni-rol-typ-title-h3-face}; // Archivo
  --uni-rol-typ-title-h3-size: #{$uni-rol-typ-title-h3-size}; // 16
  --uni-rol-typ-title-h3-weight: #{$uni-rol-typ-title-h3-weight}; // 500
  --uni-rol-typ-title-h3-line: #{$uni-rol-typ-title-h3-line}; // 150
  --uni-rol-typ-title-h3-space: #{$uni-rol-typ-title-h3-space};
  // h4
  --uni-rol-typ-title-h4-face: #{$uni-rol-typ-title-h4-face}; // Archivo
  --uni-rol-typ-title-h4-size: #{$uni-rol-typ-title-h4-size}; // 20
  --uni-rol-typ-title-h4-weight: #{$uni-rol-typ-title-h4-weight}; // 700
  --uni-rol-typ-title-h4-line: #{$uni-rol-typ-title-h4-line}; // 108%
  --uni-rol-typ-title-h4-space: #{$uni-rol-typ-title-h4-space};
  // h5
  --uni-rol-typ-title-h5-face: #{$uni-rol-typ-title-h5-face}; // Archivo
  --uni-rol-typ-title-h5-size: #{$uni-rol-typ-title-h5-size}; // 16
  --uni-rol-typ-title-h5-weight: #{$uni-rol-typ-title-h5-weight}; // 500
  --uni-rol-typ-title-h5-line: #{$uni-rol-typ-title-h5-line}; // 32
  --uni-rol-typ-title-h5-space: #{$uni-rol-typ-title-h5-space};
  // body copy
  --uni-rol-typ-copy-lg-face: #{$uni-rol-typ-copy-lg-face}; // Martel
  --uni-rol-typ-copy-lg-size: #{$uni-rol-typ-copy-lg-size}; // 20 ?
  --uni-rol-typ-copy-lg-weight: #{$uni-rol-typ-copy-lg-weight};
  --uni-rol-typ-copy-lg-line: #{$uni-rol-typ-copy-lg-line}; //32
  --uni-rol-typ-copy-lg-space: #{$uni-rol-typ-copy-lg-space};
  // medium
  --uni-rol-typ-copy-md-face: #{$uni-rol-typ-copy-md-face}; // Martel
  --uni-rol-typ-copy-md-size: #{$uni-rol-typ-copy-md-size}; // 16
  --uni-rol-typ-copy-md-weight: #{$uni-rol-typ-copy-md-weight}; //400
  --uni-rol-typ-copy-md-line: #{$uni-rol-typ-copy-md-line}; // 168%
  --uni-rol-typ-copy-md-space: #{$uni-rol-typ-copy-md-space}; //normal
  // small
  --uni-rol-typ-copy-sm-face: #{$uni-rol-typ-copy-sm-face}; // Martel
  --uni-rol-typ-copy-sm-size: #{$uni-rol-typ-copy-sm-size}; // 12
  --uni-rol-typ-copy-sm-weight: #{$uni-rol-typ-copy-sm-weight}; // 400
  --uni-rol-typ-copy-sm-line: #{$uni-rol-typ-copy-sm-line}; // 130%
  --uni-rol-typ-copy-sm-space: #{$uni-rol-typ-copy-sm-space}; //normal
  // inputs
  --uni-rol-typ-form-description-face: #{$uni-rol-typ-form-description-face}; // Archivo
  --uni-rol-typ-form-description-size: #{$uni-rol-typ-form-description-size}; // 14
  --uni-rol-typ-form-description-weight: #{$uni-rol-typ-form-description-weight}; //400
  --uni-rol-typ-form-description-line: #{$uni-rol-typ-form-description-line}; // 168%
  --uni-rol-typ-form-description-space: #{$uni-rol-typ-form-description-space}; //normal
  // input label
  --uni-rol-typ-form-label-face: #{$uni-rol-typ-form-label-face}; // Archivo
  --uni-rol-typ-form-label-size: #{$uni-rol-typ-form-label-size}; // 12
  --uni-rol-typ-form-label-weight: #{$uni-rol-typ-form-label-weight}; // 400
  --uni-rol-typ-form-label-line: #{$uni-rol-typ-form-label-line}; // 130%
  --uni-rol-typ-form-label-space: #{$uni-rol-typ-form-label-space}; //normal
  // input form
  --uni-rol-typ-form-input-face: #{$uni-rol-typ-form-input-face}; // Archivo
  --uni-rol-typ-form-input-size: #{$uni-rol-typ-form-input-size}; // 16
  --uni-rol-typ-form-input-weight: #{$uni-rol-typ-form-input-weight}; // 400
  --uni-rol-typ-form-input-line: #{$uni-rol-typ-form-input-line}; // 150%
  --uni-rol-typ-form-input-space: #{$uni-rol-typ-form-input-space}; // 0.5

  // ---------------------- Size & Scale Role Tokens ----------------------------------
  --uni-rol-spc-gutter: #{$uni-rol-spc-gutter}; // 24px;
  --uni-rol-spc-margin: #{$uni-rol-spc-margin}; // 16px
  --uni-rol-spc-padding-vrt: #{$uni-rol-spc-padding-vrt}; // 16px
  --uni-rol-spc-padding-hz: #{$uni-rol-spc-padding-hz}; // 24px;

  // breaks
  --uni-rol-spc-break-md: 768px;
  --uni-rol-spc-break-lg: 992px;
  --uni-rol-spc-break-xl: 1140px;
  --uni-rol-spc-break-xxl: 1500px;
  --uni-rol-spc-break-modal-regular: 600px;
}
