@use "design-sys" as *;

.form-grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, 1fr);
  gap: 0 $uni-rol-spc-gutter-md;

  .full-span,
  mat-form-field {
    grid-column-end: span 12;
  }

  .three-quarters-span {
    grid-column-end: span 9;
  }

  .half-span {
    grid-column-end: span 6;
  }

  .one-third-span {
    grid-column-end: span 4;
  }

  .two-thirds-span {
    grid-column-end: span 8;
  }

  .one-quarter-span {
    grid-column-end: span 3;
  }

  mat-divider {
    width: 100%;
    margin: 0 0 calc(var(--mat-form-field-container-vertical-padding) * 1.2);
  }

  hr {
    @extend mat-divider;
    height: 0.75px;
    border: 0 none;
    color: var(--mdc-outlined-text-field-outline-color);

    line-height: 1rem;
    opacity: 1;
  }

  mat-button-toggle-group {
    margin: auto;
  }

  :has(mat-button-toggle-group) {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 0 0 var(--mat-form-field-container-vertical-padding);
  }

  mat-form-field button[mat-icon-button][matsuffix] {
    --mat-icon-color: #{$uni-base-palette-neutral-variant50};
  }
}

@media screen and (max-width: $uni-rol-spc-break-modal-regular) {
  .form-grid {
    .full-span,
    .three-quarters-span,
    .two-thirds-span,
    .half-span {
      grid-column-end: span 12;
    }

    .one-third-span,
    .one-quarter-span {
      grid-column-end: span 6;
    }
  }
}
