@use "design-sys" as *;

:root {
  // raised filled primary buttons
  --mat-stepper-container-color: transparent;
  
  @media (max-width: 1380px) {
      .mat-horizontal-stepper-wrapper {
          max-width: 1060px;
      }
  
  }

  @media (max-width: 1280px) {
      .mat-horizontal-stepper-wrapper {
          max-width: 980px;
      }
    }

  @media (max-width: 1180px) {
      .mat-horizontal-stepper-wrapper {
          max-width: 880px;
      }
  }

  @media (max-width: 1080px) {
      .mat-horizontal-stepper-wrapper {
          max-width: 820px;
      }
    }

  @media (max-width: 1040px) {
      .mat-horizontal-stepper-wrapper {
          max-width: 770px;
      }
    }

  @media (max-width: 980px) {
      .mat-horizontal-stepper-wrapper {
          max-width: 710px;
      }
    }

  @media (max-width: 820px) {
      .mat-horizontal-stepper-wrapper {
          max-width: 620px;
      }
    }

  @media (max-width: 740px) {
      .mat-horizontal-stepper-wrapper {
          max-width: 500px;
      }
    }

  .stepper-form {
    .step-container {
      // @TODO-sjds vars 
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      padding: 2rem 2rem 2rem 2rem;
      background-color: #fff;
      border-radius: var(--borderRadius);
      margin-left: 0;
    }
  }

  // 
  .formActionsRow,
  .form-action-row,
  .row.form-action-row,
  .row.formActionsRow {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    // margin-bottom: 2rem;
    padding: 1rem 1rem 1rem 0rem;
    gap: 1.5rem;
    &.form-group-actions {
      margin-top: 0;
    }
    button {
      width: auto;
      min-width: 5rem;
      max-width: 15rem;
    }
  }

  .grid-container {
      display: grid;
      //grid-template-columns: repeat(auto-fit, 8rem);
      grid-template-columns: repeat(8, minmax(6rem, 15rem));
      gap:1rem;

      &.collapsable {
        position: relative;
        padding-left: -2px;
        margin-bottom: 1rem;
        div.drawer-title{
          padding-left: 0;
          .drawer-label {
            margin-left: -2px;
            line-height: 24px;
            .mat-icon {
              position: relative;
              left: -0.25rem;
              top: 0.1rem;
              font-size: 2rem;
              transform: rotate(0deg);
            }
          }
        }
        &.isOpen {
          div.drawer-title {
            h3.drawer-label {
              .mat-icon {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
      &.mathrow,
      &.math-row {
        display: flex;
        flex-direction: row;
        flex-wrap:nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 1rem;
        gap: 1rem;

        > * {
          width: auto;
          padding-left: 0;
          padding-right: 0;
          font-family: Archivo;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.25rem;
        }
        > h4.row-label{
            height: 1.25rem;
         }
        .mat-mdc-form-field,
        .mock-mdc-text-field-wrapper-stylee  { 
          flex: 1 1 auto;
          width: auto;
          min-width: 6rem;
          max-width: 8rem;
          .mat-mdc-text-field-wrapper{
            color: #857370;
            .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, 
            .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above  {
              color: #857370;
            }
          }

        }
        div.mock-mdc-wrapper-stylee,
        div.mock-mdc-text-field-wrapper-stylee {
          position: relative;
          padding-top: 1rem;
          padding-left: 1rem;
          padding-right: 1rem;
          &:nth-of-type(1) {
            font-size: 24px;
            max-width: 4.25rem;
            min-width: 4rem;
          }

          &.outlined-stylee {
            min-width: 7rem;
            border: 1px solid rgba(0, 0, 0, 0.38);
            border-radius: 4px;
          }

          &.disabled-stylee{
            color: gray;
          }

          &.operand {
            min-width: 1rem;
            max-width: 1.25rem;
            padding-top: 0.5rem;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            text-align: center;
            font-size: 1.25rem;
            line-height: 40px;
            font-weight: 500;
          }

          .mock-mdc-brow-label-stylee {
            //styleName: M3/body/small;
            position: absolute;
            top: -0.5rem;
            left: 0.5rem;
            width: auto;
            padding: 0 0.25rem;
            font-family: Archivo;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            white-space: nowrap;
            background: #FFF;
            color: #191C1D;
          }
        }

        .mock-mdc-group-wrapper-stylee,
        div.mock-mdc-group-wrapper-stylee {
          display: flex;
          flex-direction: row;
          flex-wrap:nowrap;
          justify-content: flex-start;
          align-content: flex-start;
          gap: 0.15rem;
          max-width: 24rem;
          > .mat-mdc-form-field {
            margin-right: 1rem;
          }
        }

      }
      .frm-sec-header {
        width: 100%;
        font-size: 1.375rem;
        font-weight: 500;
      }
      .frm-grp-header {
        width: 100%;
        font-size: 1rem;
        font-weight: 500;
      }
      .frm-grp-descriptor {
        font-family: Martel;
        font-size: 0.88rem;
        font-weight: 400;
        line-height: 1.625rem;
        a {
          color: #A93729;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .frm-sec-header,
      .frm-grp-header,
      .frm-grp-descriptor,
      .row-label,
      .row-comment,
      .row-area,
      .search-row,
      .form-action-row  {
          grid-column: span all;
          grid-column: 1 / -1;
          // margin-bottom: 0;
          margin-top:  0rem; // 1rem;
          margin-bottom: 0rem; // 0.25rem;
          padding: 0;
          .mat-mdc-form-field.notes-textarea {
              min-width: 35rem;
              max-width: 80%;
              min-height: 7.5rem; // makes wrapper bigger, but not text area
              .mat-mdc-text-field-wrapper.mdc-text-field {
                  min-height: 10rem; // doing nothing
                  .mat-mdc-form-field-flex {
                      min-height: 11rem;  // doing nothing
                      .mat-mdc-text-field-wrapper.mdc-text-field{
                          min-height: 12rem;  // doing nothing
                          .mat-mdc-form-field-infix{
                              min-height: 13rem;  // doing nothing
                              .mat-mdc-input-element{
                                  min-height: 14rem;  // doing nothing
                              }
                          }
                      }
                  }
              }
          }
      }
      .proclamation {
        // grid-column: span all;
        grid-column: 1 / 8;
      }
      .grid-item {
        padding: 0;

        &.total{
          .fauxField,
          .fixed-field  {
            font-size: 1.5rem;
            width:auto;
            max-width: 100%;
            height: 3.5rem;
            > * {line-height: 3.5rem;}
          }
        }

        // .fauxField{            
        //   width:auto;
        //   max-width: 100%;
        //   height: 3.5rem;
        //   > * {line-height: 3.5rem;}
        // }
      }
      .row-label{
        font-size: 1rem;
        font-weight: 500;
      }
  }

  // @TODO-sjds  proclamation
  /* 

    make component
    pdetail
    pricing
    po add
    invoice add

    padding 1rem
    icon 1.5rem
    16/ 17.41px
    rad 0.5rem

    info
    color #055160
    icon #055160
    bg #CFF4FC
    border #9EEAF9

    addtl info (!) error
    text #664D03
    icon #664D03
    info fill: #FFF3CD
    info border: #FFE69C

    /!\ waring
    M3/sys/light/error-container
    color  #58151C
    bg #FFDAD6
    border #410002
  */
  .proclamation {
    width: 90%;
    padding: 1rem 1rem;
    display: grid;
    grid-template-columns: 2.5rem auto 2rem;
    grid-template-rows: 1fr;
    grid-template-areas: "icon message action";
    justify-content:start;
    justify-items: stretch;
    align-content:start;
    gap: 0 1rem;
    background-color: #CFF4FC;
    border-radius: 0.5rem;
    border: 1px solid #9EEAF9;
    box-sizing: border-box;
    color: #055160;

    .icon{
      grid-area:icon;
      color:inherit;
      text-align: center;
      //align-self: start;
    }
    .message {
      grid-area:message;
      color:inherit;
      text-align: left;
      align-self: start;
      word-wrap: break-word; 
      overflow-wrap: break-word;
      margin-right: auto;
    }
    .action {
      grid-area:action;
      color:inherit;
      text-align: center;
      align-self: start;
    }
    &.warning-proclamation  {
      border: 1px solid #410002;
      background-color: #FFDAD6; // M3/sys/light/error-container
      color: #58151C;
    }

    &.caution-proclamation {
      border: 1px solid #FFE69C;
      background-color: #FFF3CD; // Additional Info - Fill
      color: #664D03;
    }

  }

}
