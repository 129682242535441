mat-slide-toggle {
  &.expand {
    width: 100%;
    
    [mat-internal-form-field] {
      width: 100%;
      justify-content: space-between;
    }

    label {
      margin: unset;
    }
  }
}
