* {
  box-sizing: border-box;

  .material-icons {
    font-family: 'Material Icons' !important;
    font-weight: var(--fontWeightNormal);
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  .material-icons.material-icons--outline {
    font-family: 'Material Icons Outline';
  }
}

// Variables
:root {
  /* Colors: */
  --font-size: 13px;
  --font-family: 'Archivo';
  --body-font-family: 'Martel';
  --inputHeight: 36px;
  --headerBackground: #EFEFEF;
  --headerBackgroundOpacity: rgba(239, 239, 239, 0.51);
  --menu-active-color: #121212;
  --menu-active-color-opacity: #232323;
  --iconColor: #667085;
  --white: #FFFFFF;
  --gray-opacity: rgba(248, 248, 248, 0.45);
  --menu-color: #121212;
  --info: #1E45FB;
  --info-opacity: #F4F7FF;
  // --primary-color: #007BFF;
  --primary-color: #FE793A;
  --primary-color-opacity: rgba(254, 121, 58, 0.1);
  --success-color: #499F2C;
  --success-color-opacity: #EDF6EA;
  --accent-color2: #2F4050;
  --error-color: #FF3B30;
  --error-color-opacity: #FDE9E7;
  //--warn-color-opacity: #F8E9EB;
  --warn-color-opacity: #E8F1FF;
  --warn-color: #EFA431;
  --gray: #f9f9f9;
  --gray-color-opacity: #F8F8F8;
  --card-bg: #F3F1FF;
  --card-box-shadow: 0px 3px 6px #0000000F;
  --detailsBackgroundColor: rgba(248, 248, 248, 0.5098039216);
  --tooltip-background-color: #353548;
  //testgray: rgba(128, 128, 128, 0.69);
  --borderRadius: 8px;
  --boxShadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  --boxShadowPrimary: var(--primary-color-opacity) 0px 1px 2px 0px;
  --materialBoxShadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --borderColor: #E0E0E0;
  --borderColorOpacity: rgba(232, 232, 232, 0.4);
  --backgroundColor: #F2F2F2;
  --disabled: #EAECF0;
  --disabledMaterial: rgba(189, 189, 189, 0.39);
  --transitionSpeed: 0.15s;
  --transitionSpeed2: 0.2s;
  --transitionSpeed3: 0.3s;
  --black: #121212;
  --blackOpacity: rgba(18, 18, 18, 0.55);
  --miniNavWidth: 90px;
  --longNavBarWidth: 220px;
  --fontWeightNormal: normal;
  --tableHeaderBackground: white;
  --tableHoverBoxShadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  --scheduleCellHeight: 55px;
  --scheduleCellWidth: 80px;
  //font sizes
  --body-font-family: 'Martel';
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-300: 300px;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-size-8: 8px;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-13: 13px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-24: 24px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-48: inherit;
  
  --space-base: 8px;

  .colorPrimary {
    color: var(--primary-color) !important;
  }
  .colorAccent {
    color: var(--success-color) !important;
  }
  .colorAccent2 {
    color: var(--accent-color2) !important;
  }
  .colorWarn {
    color: var(--error-color) !important;
  }
  //fonts
  .body-2 {
    font-family: var(--body-font-family);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-300);
    font-size: var(--unnamed-font-size-13);
    line-height: var(--unnamed-line-spacing-48);
    letter-spacing: var(--unnamed-character-spacing-0);
  }
  .body-1 {
    font-family: var(--body-font-family);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-13);
    line-height: var(--unnamed-line-spacing-48);
    letter-spacing: var(--unnamed-character-spacing-0);
  }
  .h5 {
    font-family: var(--body-font-family);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-8);
    line-height: var(--unnamed-line-spacing-48);
    letter-spacing: var(--unnamed-character-spacing-0);
  }
  .h4 {
    font-family: var(--body-font-family);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-12);
    line-height: var(--unnamed-line-spacing-48);
    letter-spacing: var(--unnamed-character-spacing-0);
  }
  .h3 {
    font-family: var(--body-font-family);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-13);
    line-height: var(--unnamed-line-spacing-48);
    letter-spacing: var(--unnamed-character-spacing-0);
  }
  .h2 {
    font-family: var(--body-font-family);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    font-size: var(--unnamed-font-size-18);
    line-height: var(--unnamed-line-spacing-48);
    letter-spacing: var(--unnamed-character-spacing-0);
  }
  .h1 {
    font-family: var(--body-font-family);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: var(--unnamed-font-size-24);
    line-height: var(--unnamed-line-spacing-48);
    letter-spacing: var(--unnamed-character-spacing-0);
  }
}

.pageTitle {
  font-size: var(--unnamed-font-size-24);
  color: var(--black);
  font-weight: var(--fontWeightNormal);
}
.warnColor {
  color: var(--error-color);
}

.iconColor {
  color: var(--iconColor);
}

.pageText {
  font-size: 18px;
  color: var(--black);
  font-weight: var(--fontWeightNormal);
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
  margin-top: 15px;
  width: 100px;
  height: 20px;
  line-height: 1.3;
}
