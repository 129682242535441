/** --------------------------- Root Tokens ------------------------------- 
 * Root tokens are abstract encodings of raw values. These were produced (and
 * will be edited) manually. Plan to replace these with generated files once
 * token service is built.
 * ------------------------------------------------------------------------*/

/* ------------------------- Base Color Tokens ----------------------------*/
$uni-base-palette-primary0: #000000;
$uni-base-palette-primary10: #410000;
$uni-base-palette-primary20: #670402;
$uni-base-palette-primary25: #77120b;
$uni-base-palette-primary30: #881f15;
$uni-base-palette-primary35: #982b1f;
$uni-base-palette-primary40: #a93729;
$uni-base-palette-primary50: #ca4f3f;
$uni-base-palette-primary60: #eb6756;
$uni-base-palette-primary70: #ff8a79;
$uni-base-palette-primary80: #ffb4a8;
$uni-base-palette-primary90: #ffdad5;
$uni-base-palette-primary95: #ffedea;
$uni-base-palette-primary98: #fff8f6;
$uni-base-palette-primary99: #fffbff;
$uni-base-palette-primary100: #ffffff;
// Secondary
$uni-base-palette-secondary0: #000000;
$uni-base-palette-secondary10: #30130f;
$uni-base-palette-secondary20: #492722;
$uni-base-palette-secondary25: #56322c;
$uni-base-palette-secondary30: #633d37;
$uni-base-palette-secondary35: #704842;
$uni-base-palette-secondary40: #7d544d;
$uni-base-palette-secondary50: #996c65;
$uni-base-palette-secondary60: #b5857e;
$uni-base-palette-secondary70: #d29f97;
$uni-base-palette-secondary80: #f0bab2;
$uni-base-palette-secondary90: #ffdad5;
$uni-base-palette-secondary95: #ffedea;
$uni-base-palette-secondary98: #fff8f6;
$uni-base-palette-secondary99: #fffbff;
$uni-base-palette-secondary100: #ffffff;
// Tertiary
$uni-base-palette-tertiary0: #000000;
$uni-base-palette-tertiary10: #251a00;
$uni-base-palette-tertiary20: #3f2e00;
$uni-base-palette-tertiary25: #4d3800;
$uni-base-palette-tertiary30: #5a4305;
$uni-base-palette-tertiary35: #674f11;
$uni-base-palette-tertiary40: #745b1d;
$uni-base-palette-tertiary50: #8f7333;
$uni-base-palette-tertiary60: #aa8d4a;
$uni-base-palette-tertiary70: #c7a762;
$uni-base-palette-tertiary80: #e4c37a;
$uni-base-palette-tertiary90: #ffdf9b;
$uni-base-palette-tertiary95: #ffefd3;
$uni-base-palette-tertiary98: #fff8f2;
$uni-base-palette-tertiary99: #fffbff;
$uni-base-palette-tertiary100: #ffffff;
// Neutral
$uni-base-palette-neutral0: #000000;
$uni-base-palette-neutral10: #201a19;
$uni-base-palette-neutral20: #362f2e;
$uni-base-palette-neutral25: #413a38;
$uni-base-palette-neutral30: #4d4544;
$uni-base-palette-neutral35: #59504f;
$uni-base-palette-neutral40: #655c5b;
$uni-base-palette-neutral50: #7f7573;
$uni-base-palette-neutral60: #998e8d;
$uni-base-palette-neutral70: #b4a9a7;
$uni-base-palette-neutral80: #d0c4c2;
$uni-base-palette-neutral90: #ede0dd;
$uni-base-palette-neutral95: #fbeeec;
$uni-base-palette-neutral98: #fff8f6;
$uni-base-palette-neutral99: #fffbff;
$uni-base-palette-neutral100: #ffffff;

$uni-base-palette-neutral-variant0: #000000;
$uni-base-palette-neutral-variant10: #251917;
$uni-base-palette-neutral-variant20: #3b2d2b;
$uni-base-palette-neutral-variant25: #473836;
$uni-base-palette-neutral-variant30: #534341;
$uni-base-palette-neutral-variant35: #5f4f4c;
$uni-base-palette-neutral-variant40: #6b5a58;
$uni-base-palette-neutral-variant50: #857370;
$uni-base-palette-neutral-variant60: #a08c89;
$uni-base-palette-neutral-variant70: #bba7a3;
$uni-base-palette-neutral-variant80: #d8c2be;
$uni-base-palette-neutral-variant90: #f5ddda;
$uni-base-palette-neutral-variant95: #ffedea;
$uni-base-palette-neutral-variant98: #fff8f6;
$uni-base-palette-neutral-variant99: #fffbff;
$uni-base-palette-neutral-variant100: #ffffff;
// Error
$uni-base-palette-error0: #000000;
$uni-base-palette-error10: #410002;
$uni-base-palette-error20: #690005;
$uni-base-palette-error25: #7e0007;
$uni-base-palette-error30: #93000a;
$uni-base-palette-error35: #a80710;
$uni-base-palette-error40: #ba1a1a;
$uni-base-palette-error50: #de3730;
$uni-base-palette-error60: #ff5449;
$uni-base-palette-error70: #ff897d;
$uni-base-palette-error80: #ffb4ab;
$uni-base-palette-error90: #ffdad6;
$uni-base-palette-error95: #ffedea;
$uni-base-palette-error98: #fff8f7;
$uni-base-palette-error99: #fffbff;
$uni-base-palette-error100: #ffffff;

// Warning
$uni-base-palette-warning-50: #FF9500;

// Info
$uni-base-palette-info-50: #007AFF;

// Alt
// @TODO-sjds. flesh these out?
$uni-base-palette-alt-pos-40: #109325;
$uni-base-palette-alt-pos-90: #d7fbd7;
$uni-base-palette-alt-add-40: #ffe69c;
$uni-base-palette-alt-add-90: #fff3cd;
$uni-base-palette-alt-info-40: #9eeaf9;
$uni-base-palette-alt-info-90: #cff4fc;
$uni-base-palette-alt-brand-40: #fe793a;


/* -------------- Base Size & Scale Tokens ----------------*/
// @TODO-sjds. align with proper fib scale? align with font scale?

// dimensions scale
$uni-base-size-scale: 4px; // 1/4 base font
$uni-base-size-xxs: 0.125rem; // =2px  base/2
$uni-base-size-xs: 0.25rem; // =4px  base
$uni-base-size-sm: 0.5rem; // =8px  base*2
$uni-base-size-med: 1rem; // =16px  base*2
$uni-base-size-lg: 1.5rem; // =24px  base*6 1.5rem
$uni-base-size-xl: 2rem; // =32px  base*8
$uni-base-size-xxl: 3rem; // =48px  base*12
$uni-base-size-xxxl: 4rem; // =64px  base*16

// shape scale (border-radius)
$uni-base-shape-none: 0;
$uni-base-shape-xs: 4px;
$uni-base-shape-sm: 8px;
$uni-base-shape-med: 12px;
$uni-base-shape-lg: 16px;
$uni-base-shape-xl: 28px;
$uni-base-shape-full: 9999px;

// time scale
$uni-base-chron-instant: 330;
$uni-base-chron-quick: 150;
$uni-base-chron-regular: 250;
$uni-base-chron-gradual: 400;
$uni-base-chron-slow: 600;

/* -------------- Base Typography Tokens ----------------*/
$uni-base-type-font-ui: "Archivo";
$uni-base-type-font-content: "Martel";
$uni-base-type-size-copy: $uni-base-size-med;

$uni-base-type-size-root: 16px;
$uni-base-type-size-xsmall: 0.75rem; // 12
$uni-base-type-size-small: 0.875rem; // 14
$uni-base-type-size-regular: 1rem; // 16
$uni-base-type-size-large: 1.25rem; // 20
$uni-base-type-size-xlarge: 1.375rem; // 22
$uni-base-type-size-xxlarge: 2rem; // 28
$uni-base-type-size-xxxlarge: 2.5rem; // 40

// @TODO-sjds. possibly replace % with other scale?
$uni-base-type-line-short: 108%;
$uni-base-type-line-regular: 127%;
$uni-base-type-line-tall: 130%;
$uni-base-type-line-xtall: 150%;
$uni-base-type-line-xxtall: 168%;

$uni-base-type-spacing-tight: 0.15px;
$uni-base-type-spacing-regular: normal;
$uni-base-type-spacing-wide: 0.5px;

$uni-base-type-weight-regular: 400;
$uni-base-type-weight-bold: 500;
$uni-base-type-weight-extrabold: 600;
$uni-base-type-weight-black: 700;
