app-dashboard-widget-base {
  mat-tab-group {
    height: 100%;
    .mat-mdc-tab-body-wrapper {
      flex: 1 1 100%;
      container-type: size;

      .mat-mdc-tab-body-content {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden auto;
      }
    }
  }
}
