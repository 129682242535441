@use "@angular/material" as mat;
@use "@angular/material-experimental" as matx;

// Design Token scss var import
@use "design-sys" as *;

/* ----------------------------- Limited Reset--------------------------------- */
html,
:root {
  height: 100%;
  margin: 0;

  font-size: $uni-base-type-size-root;

  body {
    // TODO(jg): Remove this once Bootstrap stylesheet is eliminated and/or
    // all Bootstrap token—dependent styles are migrated to Unisight tokens
    // --body-font-family: var(--uni-rol-typ-label-face);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: $uni-rol-typ-title-face;
    font-size: $uni-rol-typ-default-size;
    background-color: var(--backgroundColor);
  }

  // block
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    // margin-top: 0;
    // margin-left: 0;
    // margin-right: 0;
    // margin-bottom: $uni-rol-spc-margin;
    // box-sizing: border-box;
  }
  // float
  a,
  span {
    padding: 0;
    margin: 0;
  }
}

a {
  text-decoration: none;
}

// h1 {
//   font-family: $uni-rol-typ-title-h1-face;
//   font-size: $uni-rol-typ-title-h1-size;
//   font-weight: $uni-rol-typ-title-h1-weight;
//   letter-spacing: $uni-rol-typ-title-h1-space;
//   -webkit-font-smoothing: antialiased;
// }
// h2 {
//   font-family: $uni-rol-typ-title-h2-face;
//   font-size: $uni-rol-typ-title-h2-size;
//   font-weight: $uni-rol-typ-title-h2-weight;
//   letter-spacing: $uni-rol-typ-title-h2-space;
//   -webkit-font-smoothing: antialiased;
// }
// h3 {
//   font-family: $uni-rol-typ-title-h3-face;
//   font-size: $uni-rol-typ-title-h3-size;
//   font-weight: $uni-rol-typ-title-h3-weight;
//   letter-spacing: $uni-rol-typ-title-h3-space;
//   -webkit-font-smoothing: antialiased;
// }
// p {
//   font-family: $uni-rol-typ-copy-lg-face;
//   font-size: $uni-rol-typ-copy-lg-size;
//   font-weight: $uni-rol-typ-copy-lg-weight;
//   -webkit-font-smoothing: antialiased;
// }

/* ------------------------------- End  Reset --------------------------------- */

// Design variables and theming
:root {
  /* Unisight design variables */
  @include uni-role-custom-props();

  /* Angular Material design variables */
  @include mat.all-component-themes($unisight-theme);

  /* Unisight M3 global configuration */
  @include uni-am3-global-conf();

  //TODO(jg): migrate component color variants to explicit classes, or eliminate (see https://material.angular.io/guide/material-3#using-component-color-variants)
  /* Backward compatibility for Angular Material component color variants */
  @include matx.color-variants-back-compat($unisight-theme);
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// ---------------------------- Component & Section Styles ---------------------------------
@import "theme";
@import "global";
@import "animations";
@import "mdc-overrides";
@import "custom-scheme";
@import "form-grid";

@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
