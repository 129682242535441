@use "design-sys" as *;

.notification__menu, .menu__extended-format {
  font-size: $uni-rol-typ-copy-md-size;

  hr {
    margin: $uni-base-size-sm 0;
    color: #d8c2be;
  }

  button[mat-menu-item] {
    max-width: 15rem;
    padding: var(--space-base);
    mat-icon {
      white-space: nowrap;
      min-width: 1.5rem;
    }
    p {
      margin: auto 0;
      color: #191c1d;
      line-height: normal;
      &.explanation {
        white-space: wrap;
        margin: 0;
        font-size: $uni-base-type-size-xsmall;
        color: $uni-rol-clr-on-surface-variant-light;
      }
    }
  }
}
