@use 'design-sys/unisight' as *;
/**
* Emits app-wide configuration for Angular Material 3 design variables
*/
@mixin global-conf(){
  //--mat-app-background-color: $uni-base-palette-neutral99;
  //--mat-app-text-color: $uni-base-palette-neutral0;
  //--mat-ripple-color: rgba(32, 26, 23, 0.1);
  //--mat-option-selected-state-label-text-color: #2b1708;
  //--mat-option-label-text-color: #201a17;
  //--mat-option-hover-state-layer-color: rgba(32, 26, 23, 0.08);
  //--mat-option-focus-state-layer-color: rgba(32, 26, 23, 0.12);
  //--mat-option-selected-state-layer-color: #ffdcc7;
  //--mat-option-label-text-font: Martel;
  //--mat-option-label-text-line-height: 1.25rem;
  //--mat-option-label-text-size: 1rem;
  //--mat-option-label-text-tracking: 0.006rem;
  //--mat-option-label-text-weight: 400;
  //--mat-full-pseudo-checkbox-selected-icon-color: $uni-rol-clr-primary-light;
  //--mat-full-pseudo-checkbox-selected-checkmark-color: $uni-base-palette-secondary100
  //--mat-full-pseudo-checkbox-unselected-icon-color: #52443c;
  //--mat-full-pseudo-checkbox-disabled-selected-checkmark-color: $uni-base-palette-neutral99;
  //--mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(32, 26, 23, 0.38);
  //--mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(32, 26, 23, 0.38);
  //--mat-minimal-pseudo-checkbox-selected-checkmark-color: $uni-rol-clr-primary-light;
  //--mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(32, 26, 23, 0.38);
  //--mdc-elevated-card-container-color: $uni-base-palette-neutral99;


  // @todo-simon i expect we'll want to use these overrides in the near future 2024-03-25
  // button font
  //--mdc-protected-button-label-text-font: var(--uni-rol-typ-label-face);
  // placeholter / label font
  //--mdc-outlined-text-field-label-text-font: var(--uni-rol-typ-label-face);
  // form
  //--mat-form-field-container-text-font: var(--uni-rol-typ-label-face);
  //menu font
  //--mat-menu-item-label-text-font: var(--uni-rol-typ-label-face);
  //tab
  //--mat-tab-header-label-text-font: var(--uni-rol-typ-label-face);
  //tabel
  //--mat-table-header-headline-font: var(--uni-rol-typ-title-face);
  //--mat-table-row-item-label-text-font: var(--uni-rol-typ-label-face);
  // options
  //--mat-option-label-text-font: var(--uni-rol-typ-label-face);

  // buttons

  // table
  --mat-table-background-color: #{$uni-rol-clr-on-primary-light};
  --mat-paginator-container-background-color: #{$uni-rol-clr-on-primary-light};
  --mat-table-row-item-outline-color: #{$uni-rol-clr-outline-light};
  --mat-table-row-item-outline-width: 1px;
  --mat-table-header-headline-color: #{$uni-rol-clr-on-surface-variant-light};
  --mat-sort-arrow-color: #{$uni-rol-clr-neutral-dark};

  // --mat-option-selected-state-layer-color: #{$uni-rol-clr-surface-light};
  // --mat-select-panel-background-color: #{$uni-rol-clr-surface-light};
  //form outline 857370
  //table outline 7475
}
