@use "design-sys" as *;

:is(ngx-mat-timepicker, ngx-mat-timepicker-standalone) {
  .timepicker-header {
    &.mat-toolbar {
      --mat-toolbar-title-text-line-height: #{$uni-base-type-line-short};
    }

    &.mat-primary {
      --mat-toolbar-container-background-color: #{$uni-rol-clr-primary};
      --mat-toolbar-container-text-color: #{$uni-rol-clr-on-primary};
      --mat-toolbar-title-text-font: #{$uni-rol-typ-form-input-face};
    }

    &.mat-toolbar-single-row {
      align-content: center;
      padding: $uni-rol-spc-padding;

      :has(> .timepicker-dial__hint) {
        display: none;
      }

      .timepicker-dial__time {
        line-height: $uni-base-type-line-short;
        :is(.timepicker-dial__control, span) {
          font-size: $uni-base-type-size-xxxlarge;
        }

        .timepicker-dial__control {
          width: 2ch;
        }
      }

      button.timepicker-period__btn {
        font-size: $uni-rol-typ-copy-sm-size;
        line-height: $uni-base-type-line-short;
      }
    }
  }

  .timepicker__main-content {
    display: none;
  }

  div.ngx-mat-timepicker-standalone-actions {
    padding: $uni-base-type-size-small;
    justify-content: space-around;

    .mdc-button {
      margin: 0 $uni-base-size-xs;
    }

    :last-child button.mdc-button.mat-primary {
      background-color: #{$uni-rol-clr-primary};
      color: #{$uni-rol-clr-on-primary};
      --mat-text-button-state-layer-color: var(
        --mat-filled-button-state-layer-color
      );
      --mat-text-button-ripple-color: var(--mat-filled-button-ripple-color);
    }
  }
}
