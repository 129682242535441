@use "design-sys" as *;

:root {
--mdc-dialog-subhead-font: Archivo; 
--mdc-dialog-subhead-text-size: 1.15rem;
--mdc-dialog-subhead-line-size: 1.5rem;
--mdc-dialog-subhead-color: var( --uni-rol-clr-neutral-dark);

--mdc-dialog-supporting-text-font: Martel;
--mdc-dialog-supporting-text-size:  1.1rem;
--mdc-dialog-supporting-text-line-height: 1.5rem;
--mdc-dialog-supporting-text-color: var(--uni-rol-clr-on-surface-light);
 div.mat-mdc-dialog-content {
        min-height: 3.5rem;
        h2 {
            margin-bottom: 1rem;
        }
        p {
            display: block;
          }
     }
}